body{
    font-size: 0.9rem;
}

a:hover{
    cursor: pointer;
}

.header{
    background-image: url("../images/bg-header-dark.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: calc(100vh - 0px);
    width: 100%;
}

.navbar-line {    
    border-image: linear-gradient(#53b700, #00c1bf) 30;
    border-style: solid;
    border-bottom-width: 2px;
    border-top-width: 0;
    border-right-width: 0;
    border-left-width: 0;
}

.workspace-header{
    background: #e9f2ff;
    position: sticky;
    top: 0;
    z-index: 2;
}

.workspace{
    height: calc(100vh - 64px);
    overflow-y: auto;
    background: #f9f9f9;
}

.btn-success-gradient{
    background-image: linear-gradient(to right, #53b700, #00c1bf);
    color: #fff;
}

.btn-success-gradient:hover{
    background-image: linear-gradient(to right, #459900, #019c9b);
    color: #fff;
}

.rounded-4{
    border-radius: 0.5rem;
}

.fs-7{
    font-size: 0.7rem;
}

.btn-reverse{
    position: absolute;
    right: -16px;
    top: 50%;
    transform: translateY(-50%);
}

.btn-reverse .btn{
    background: #fff;
    color: #53b700;
    border: 1px solid #53b700;
}

.card-search{
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
}

.card-search button{
    font-size: 1.1rem;
    width: 200px;
    height: 40px;
}

.btn-group .btn-sm{
    border-radius: 50px !important;
    margin-right: 10px;
    padding: 0.2rem 1rem;
    font-size: 0.8rem;
}

.btn-check:checked+.btn-outline-info{
    color: #fff;
    background-color: #00c1bf;
    border-color: #00c1bf;
}

.btn-outline-info{
    color: #00c1bf;
    background-color: #fff;
    border-color: #00c1bf;
}

.btn-outline-info:hover, .btn-outline-info:active{
    color: #fff;
    background-color: #00c1bf;
    border-color: #00c1bf;
}

.btn-outline-info:focus{    
    box-shadow: 0 0 0 0.25rem rgba(0, 193, 191, .5);
}

.btn-success, .btn-outline-success:hover{
    background: #53b700;
    border-color: #53b700;
}

.btn-success:hover{
    background: #49a100;
    border-color: #49a100;
}

.btn-outline-success{
    background: #eeffe2;
    border-color: #53b700 !important;
    color: #53b700;
}

.text-info{
    color: #00c1bf !important;
}

.text-success{
    color: #53b700 !important;
}

.fw-semibold{
    font-weight: 500;
}

/*filter*/
.filter-header {
    padding: 0.25rem 0.5rem;
    background: #f6f6f6;
    border-radius: 0.25rem;
    font-weight: 500;
    color: #6c757d;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
    font-size: 0.85rem;
}

.filter-body{
    padding: 1rem 0.5rem;
}

.filter-amount {
    color: #6c757d;
    font-size: 0.65rem;
    font-weight: 500;
}

.filter-badge{
    font-size: 0.7rem;
    background: #e9f2ff;
    border-radius: 50px;
    padding: 0.2rem 0.5rem;
}

.list-week{
    display: flex;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
    margin-bottom: 1rem;
}

.list-week div {
    background: #fff;
    border-right: 1px solid #dee2e6;
    width: 210px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
}

.left-arrow, .right-arrow{
    width: 70px !important;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
}

.left-arrow{
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.right-arrow{
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}

.list-week div.active {
    font-weight: 500;
    background: #eeffe2;
    color: #53b700;
}

.table thead th{
    background: #f6f6f6;
    font-weight: 400;
    padding: 0.3rem 0.5rem;
    font-size: 0.8rem;
    border: none;
}

.table-flight tbody td{
    padding: 1.5rem 0.5rem;
    vertical-align: middle;
}

.table .btn{
    font-size: 0.8rem;
    font-weight: 500;
}

.sub-list {
    font-size: 0.75rem;
    color: #6c757d;
    font-style: italic;
}

.table-return tbody td{
    padding: 0rem 0.5rem 1rem;
    vertical-align: middle;
}

.table-return .row-header{
    padding: 1rem 0.5rem 0.5rem;
    color: #6c757d;
    font-weight: 500;
    border-bottom: none;
}

.non-stop, .duration{
    font-weight: 500;
    color: #53b700;
    font-size: 0.8rem;    
}

.have-stop{
    font-weight: 500;
    color: #e78567f7;
    font-size: 0.6rem;    
}

.fa-plane{
    transform: rotate(-45deg);
    font-size: 0.7rem;
    color: #0d6efd;
    font-size: 0.8rem;    
}